import React, {useEffect, useState, useCallback} from 'react';
import {useParams,useLocation} from 'react-router-dom';
import {Formik, validateYupSchema, yupToFormErrors} from 'formik';

import {useFetch, useToast} from 'helpers/hooks';
import {Detail} from 'components/Detail';
import {ProgressSpinner} from 'components/ProgressSpinner';
import {nullifyEmptyValues, stringifyNullValues, request, preProcessCosts} from 'helpers/tools';
import {DuplicateModal} from "components/DuplicateModal";
import {validationSchema} from 'helpers/validationSchema';

/*
const fetchRequiredFields = (user) => {
    if (user.role === "ClientUser"){
        return user.required_fields;
    } else if(user.role === "AccountantUser") {
        return {//Accountant ruleset
            document_type: false,
            document_identifier: false,
            document_number:false,//to be validated in case specific docs and accountant user role
            supplier_title: true,
            due_date:false,
            accounting_year: false,
            date:false,
            num_series: true,
            variable_symbol: true
        }
    } else {// Other roles ruleset
        return {
            supplier_title: true,
        }
    }

}
*/

/* !!! Attention here - required fields have to be consistent with the get_missing_fields in document.py !!!*/
const fetchRequiredFields = (user,docType,payment_type) => {
    if (user.role === "ClientUser"){
        return user.required_fields;
    } else if(user.role === "AccountantUser") {
        let reqFields = {}
        reqFields["document_type"]=true
        reqFields["accounting_year"]=true
        reqFields["supplier_title"]=true
        reqFields["client_company"]=true
        reqFields["num_series"]=true
        
        reqFields["currency"]=false
        reqFields["number"]=false
        reqFields["due_date"]=false
        reqFields["taxable_supply_date"]=false
        reqFields["variable_symbol"]=false
        

        if (["fpr", "oza", "fvy","fvz"].includes(docType)){
            reqFields["currency"]=true
            reqFields["number"]=true
            reqFields["due_date"]=true
            reqFields["taxable_supply_date"]=true
        }
        else if (["fpz"].includes(docType)){
            reqFields["number"]=true
            reqFields["due_date"]=true
        }
        else if (["pvy","ppr"].includes(docType)){
            reqFields["currency"]=true
            reqFields["taxable_supply_date"]=true
        }
        else if (["opo"].includes(docType)){
            reqFields["currency"]=true
            reqFields["due_date"]=true
            reqFields["taxable_supply_date"]=true
        }

        if (['fpr', 'fpz', 'fvy', 'fvz', 'oza'].includes(docType)){
            reqFields["variable_symbol"]=true
        }
       
        return reqFields        
    } else {// Other roles ruleset
        return {
            supplier_title: true,
        }
    }
}


export const DetailWrapper = ({user, clientCompanies, ...props}) => {
    const [duplicates, setDuplicates] = useState([]);
    const [forwarding, setForwarding] = useState(false);
    const {table, uuid} = useParams();
    const [docUuid, setDocUuid]= useState(uuid);

    const [formInitValues, setFormInitValues] = useState("")

    let doc = useFetch(`/${table}/${uuid}/`);
    let detail = useFetch(`/detail/${uuid}/`);
    const showToast = useToast();
    
   
    let location = useLocation();
    const currentTable = location.pathname.split("/")[1];
    /* 
    function updateDoc (){
        doc.fetchData()
        detail.fetchData()
    }
    */
    

    const costsInjector = (costs) => {
        costs.forEach(c => {
            c.tax = c.tax ? c.tax : (c.value_final - c.value_original).toFixed(2)
        })
        return costs;
    }

    const itemsInjector = (items) => {
        items.forEach(i => {
        })
        
        return items;
    }

    
    // replace null values with empty strings to suppress warnings for null values on select and switching between
    // controlled/uncontrolled fields; also fixes rerender of costs upon removal
    const setInitialValues = useCallback(()=>{
        const initialValues = {
            info: {},
            costs: detail.data?.costs?.length ? stringifyNullValues(costsInjector(detail.data.costs)) : [{
                tax_rate: '',
                value_original: '',
                tax:'',
                value_final: ''
            }],
            required_fields:fetchRequiredFields(user,detail?.data?.info["document_type"],detail?.data?.info["payment_type"]),
            user_role: user.role,
            items: detail.data?.items?.length ? stringifyNullValues(itemsInjector(detail.data.items)).sort((a, b) => a.id - b.id) : [],
        };
        [
            'document_type', 'note', 'document_identifier', 'number', 'date', 'taxable_supply_date', 'due_date','accounting_date','tax_deduction_date',
            'accounting_year', 'client_company', 'activity', 'centre', 'contract', 'account', 'reverse_charge',
            'supplier_identification_number', 'supplier_vat', 'supplier_title', 'supplier_street', 'supplier_city','supplier_sk_ic_dph',
            'supplier_zip_code', 'supplier_country', 'supplier_account_number', 'supplier_bank_code', 'variable_symbol',
            'payment_type', 'currency', 'num_series', 'account_code', 'vat_classification', 'vat_kv_classification', 'payment_date','rounding','template_name',"state"
        ]
            .forEach(value => initialValues.info[value] = detail.data ? detail.data.info[value] ?? '' : '');
        initialValues.info.supplier_account_number = initialValues.info.supplier_account_number.replace(/\s+/g, '');
        if (initialValues.info.payment_date === '') {
            initialValues.info.payment_date = null
        }
        if (initialValues.info.accounting_date === '') {
            initialValues.info.accounting_date = null
        }
        if (initialValues.info.tax_deduction_date === '') {
            initialValues.info.tax_deduction_date = null
        }
        if (initialValues.info.taxable_supply_date === '') {
            initialValues.info.taxable_supply_date = null
        }
        if (initialValues.info.reverse_charge === '') {
            initialValues.info.reverse_charge = false
        }
        const companyIdToSearch = parseInt(initialValues.info["client_company"])
        let countrySlug = ''
        if (companyIdToSearch){
        const foundCompany = clientCompanies.find(company => parseInt(company.id) === parseInt(companyIdToSearch));
        countrySlug = foundCompany ? foundCompany.country.slug : null;
        
        initialValues.items.forEach(i=>{
            if (!i["discount_percentage"]){i["discount_percentage"]=0}                   
        })

        if (foundCompany && foundCompany.toggle_items === false && initialValues.items.length>0 &&currentTable ==="my_documents"){
            console.log("Truncating items")
            let interCosts = []
            initialValues.costs.forEach(i=>{
                let c = {}
                c["value_original"]=i["value_original"]
                c["tax_rate"]=i["tax_rate"]
                c["tax"]=i["tax"]
                c["value_final"]=i["value_final"]
                
                interCosts.push(c)
            })
            initialValues.costs = interCosts
            initialValues.items = []
        }
        }
        
        let totalValue = initialValues.costs.reduce((sum, cost) => sum + (cost.value_final || 0), 0);
        console.log(totalValue)
        if (initialValues.costs.length===0){totalValue=0}
        sessionStorage.setItem("initCostSum",totalValue)
        sessionStorage.setItem("initInfo",JSON.stringify(initialValues.info))
        
        
        if (countrySlug === 'sk' && initialValues.info.supplier_vat ){
            initialValues.info.supplier_vat= String(initialValues.info.supplier_vat).replace(/[a-zA-Z]/g, '');
        }

        if (Boolean(initialValues.info.rounding)===false){
            initialValues.info.rounding = 0
        }
        if (JSON.stringify(formInitValues)!==JSON.stringify(initialValues)  ){
            setFormInitValues(initialValues)
        }

        console.log(initialValues)
        return initialValues
    },[detail.data,formInitValues,user,clientCompanies,currentTable])
    
    
    //let iv = setInitialValues()
    useEffect(() => {
        setDocUuid(docUuid);
        setInitialValues()
        //updateDoc()
      }, [docUuid,setInitialValues]);
      

    
    if (doc.error || detail.error) {
        return (
            <>
                <h1>Chyba</h1>
                <p> Při zpracování požadavku došlo k chybě. </p>
            </>
        )
    }
    if ((!doc.data || !detail.data) && (doc.loading || detail.loading)) {
        return <ProgressSpinner/>
    }

    const validateRequiredFields = async (value) => {
        let res;
        try {
            res = validateYupSchema(value, validationSchema, true);
        } catch (err) {
            return yupToFormErrors(err); //for rendering validation errors
        }
        return res;
    }
    
    const preProcessItems = (values) => {
        let processed_items = [];
        values.items.forEach((i)=>{
            if(i.text !== '' && (i.price_vat!=='' && i.price_original!=='')) { // filters unfilled items
                processed_items.push(i);
            }
            else if(i.text === '' && (i.price_vat!=='' && i.price_original!=='')) { // filters unfilled items
                i.text = 'Položka '+ (processed_items.length+1);
                processed_items.push(i);
            }
            else if (i.text !== '' && (i.price_vat==='' && i.price_original==='')) { // filters unfilled items
                i.price_vat = 0
                i.price_original = 0
                processed_items.push(i);
            }

            
            if (!i["centre"]){
                i["centre"] = doc.data.document_info["centre"]
            }

            if (!i["vat_classification"]){
                i["vat_classification"] = doc.data.document_info["vat_classification"]
            }

            if (!i["vat_kv_classification"]){
                i["vat_kv_classification"] = doc.data.document_info["vat_kv_classification"]
            }

            if (!i["reverse_charge"]){
                i["reverse_charge"] = doc.data.document_info["reverse_charge"]
            }

            if (!i["contract"]){
                i["contract"] = doc.data.document_info["contract"]
            }

            if (!i["activity"]){
                i["activity"] = doc.data.document_info["activity"]
            }

            if (!i["account_code"]){
                i["account_code"] = doc.data.document_info["account_code"]
            }
                    
        })
        return processed_items;
    }

    const handleSubmit = async (values, actions) => {
        let abort = false
        for (let i = 0; i<values.costs.length; i++){
            if (props.taxRates?.find(rate => rate.id === parseInt(  values.costs[i].tax_rate))?.slug.endsWith('unknown') ){
                
                showToast('Neznámá daňová sazba. Opravte daňovou sazbu.');
                abort = true;
                break;
            }
        }
        if (abort){
            return
        }

        let validity = await validateRequiredFields(values);
        if (!validity) {
            return;
        }
        actions.setSubmitting(true);

        for (let i = values.costs.length - 1; i >= 0; i--) { // iterate backwards to remove costs
            if (Object.values(values.costs[i]).every(value => !value)) {
                values.costs.splice(i, 1); // remove empty objects (i.e. the default cost when none are set)
            }
        }

        for (let i = values.items.length - 1; i >= 0; i--) { // iterate backwards to remove items
            if (Object.values(values.items[i]).every(item => !item)) {
                values.items.splice(i, 1); // remove empty items
            }
        }

        const force = duplicates.length ? '?force=true' : '';

        let outputItems =  stringifyNullValues(preProcessItems(values))
        for (let i = 0; i < outputItems.length; i++){
            if (outputItems[i].guarantee === '' ){ outputItems[i].guarantee = null; }
            if (outputItems[i].reverse_charge === '' ){ outputItems[i].reverse_charge = false; }
            if (outputItems[i].expiration_date === '' ){ outputItems[i].expiration_date = null; }
        }

        const response = await request(`/detail/${uuid}/${force}`, 'PUT', {
            ...values,
            costs: nullifyEmptyValues(preProcessCosts(values.costs)),
            items: outputItems
        });

        if (response.ok) {
            detail.setData(response.body);
            // set ids of created costs to values so that subsequent saves update these costs rather than creating new
            values.costs = stringifyNullValues(response.body.costs);
            if (!forwarding) {
                showToast('Dokument byl uložen');
            }
        } else if (response.status === 403) {
            setDuplicates(response.body)
        } else {
            actions.setErrors(response.body)
        }
        actions.setSubmitting(false);
        return response.ok;
    };
    

    if (formInitValues===""){
        setInitialValues()
    }
    return (
        <Formik
            //validateOnMount¨
            validateOnChange={ false}
            enableReinitialize={true}
            initialValues={formInitValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            <>
                <Detail
                    doc={doc.data}
                    detail={detail}
                    requiredFields={fetchRequiredFields}
                    canCapture={user.uses_ocr}
                    canEdit={user.can_edit}
                    role={user.role}
                    user={user}
                    clientCompanies={clientCompanies}
                    setDocUuid={setDocUuid}
                    setForwarding={setForwarding}
                    setDuplicates={setDuplicates}
                    formInitValues={formInitValues}
                    {...props}
                >
                </Detail>
                { (duplicates&& duplicates.length>0) && (
                    <DuplicateModal
                        duplicates={duplicates}
                        forwarding={forwarding}
                        setDuplicates={setDuplicates}
                        setForwarding={setForwarding}
                        update={()=>detail.fetchData()}
                    />
                )}
            </>
        </Formik>
    )
};