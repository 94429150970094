import React from 'react';

import {Button} from 'components/Button';
import {Modal} from 'components/Modal';
import {request} from 'helpers/tools';
import styles from 'css_modules/Modal.module.css';


export const RemoveModal = ({close, doc, update, user, hasTemplate}) => {
    let message = 'Přesunout mezi smazané?';
    if (hasTemplate){
        message = 'Přesto přesunout mezi smazané?';
    }

    const removeTemplate = async (template) => {
        close();
        const markAsDeleted = () => request('/remove_template/', 'POST', 
        {"document":String(template.uuid),
        "id":String(template.id)})
        await markAsDeleted();
        update()
    };

    const remove = async () => {
        close();
        if (hasTemplate){removeTemplate(hasTemplate)}
        const markAsDeleted = () => request(`/my_documents/${doc.uuid}/remove/`, 'POST');
        await markAsDeleted();
        update()
    };

    return (
        <div className={styles.ontop}>
        <Modal closeOnClickOutside name={doc.name} close={close}>
            {hasTemplate && 
            <p className={styles.body}>K dokumentu je navázána šablona:  {hasTemplate.name}</p>
            }
            <p className={styles.body}>{message}</p>
            <div className={styles.buttons}>
                <Button onClick={close}>Zavřít</Button>
                <Button primary onClick={remove}>Smazat</Button>
            </div>
        </Modal>
        </div>
    )
};