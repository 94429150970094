import React from 'react';

import {Radio} from 'components/Radio';
import styles from 'css_modules/YesNo.module.css';

export const YesNo = ({heading, ...props}) => {

    return (
        <div>
            <h2 className={styles.heading}>{heading}</h2>
            <div className={styles.inputs}>
                <Radio label="Ano" value="true" {...props}/>
                <Radio label="Ne" value="false" {...props}/>
            </div>
        </div>
    )
}