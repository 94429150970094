import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useFormikContext} from 'formik';
import {request} from 'helpers/tools';
import styles from 'css_modules/Autocomplete.module.css';

export const Autocomplete = ({disabled, field, form, placeholder, searchField}) => {
    const [items, setItems] = useState([]); // supplier companies matching database records
    const [highlightedItem, setHighlightedItem] = useState(0);
    const [selectedItem, setSelectedItem] = useState(null); // used for preventing repeated search upon select
    const {errors,validateForm} = useFormikContext(); 

    const inputRef = useRef(null);

    field.onBlur = () => setItems([]);

    const placeholderText = !disabled && form.values.info.client_company ? placeholder : '';
    const inputIsFocused = document.activeElement === document.getElementsByName(field.name)[0];
    const listClassName = items.length ? styles.visibleList : styles.hiddenList;

    const selectItem = useCallback(item => {
        setSelectedItem(item[searchField]);
        form.setFieldValue('info.supplier_identification_number', item.identification_number);
        form.setFieldValue('info.supplier_vat', item.vat || '', false);
        form.setFieldValue('info.supplier_title', item.title || '', false);
        form.setFieldValue('info.supplier_street', item.street || '', false);
        form.setFieldValue('info.supplier_city', item.city || '', false);
        form.setFieldValue('info.supplier_zip_code', item.zip_code || '', false);
        form.setFieldValue('info.supplier_country', item.country || '', false);
        form.setFieldValue('info.supplier_account_number', item.account_number || '', false);
        form.setFieldValue('info.supplier_bank_code', item.bank_code || '', false);
        setItems([]);
        validateForm();
        console.log(errors)
    }, [form, searchField,errors,validateForm])

    useEffect(() => { // search DB
        let handle;
        if (inputIsFocused) {
            const search = async () => {
                const url =
                    `/supplier_companies/?search=${field.value}&search_field=${searchField}&company=${
                        form.values.info.client_company || ''}`;
                const response = await request(url);

                console.log(response)
                if (inputRef.current?.value !== '') { // set suggestions if the input is not empty
                    setItems(response.body);
                    setHighlightedItem(0)
                }
            };

            if (field.value && form.values.info.client_company && selectedItem !== field.value) {
                handle = setTimeout(() => search(), 150); // debouncer
            } else {
                setItems([])
            }
        }
        return () => clearTimeout(handle)
    }, [form.values.info.client_company, field.value, inputIsFocused, searchField, selectedItem]);

    useEffect(() => { // keyboard navigation
        const handleKeyDown = event => {
            if (inputIsFocused) {
                if (event.key === 'ArrowUp') {
                    event.preventDefault(); // prevent caret from moving to input start
                    setHighlightedItem(current => current ? current - 1 : items.length - 1);
                } else if (event.key === 'ArrowDown') {
                    setHighlightedItem(current => current < items.length - 1 ? current + 1 : 0);
                } else if (event.key === 'Enter' && items.length) {
                    event.preventDefault(); // prevent form submission
                    selectItem(items[highlightedItem]);
                } else if (event.key === 'Escape') {
                    setItems([]);
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [highlightedItem, inputIsFocused, items, selectItem]);

    const renderItems = (item, i) => {
        const className = i === highlightedItem ? `${styles.item} ${styles.highlight}` : styles.item;

        const handleSelect = () => selectItem(item);

        return (
            <li
                key={item.id}
                onMouseDown={handleSelect}
                className={className}>
                 {searchField === "vat" ? `${item.vat}: ${item.title}` : `${item.identification_number}: ${item.title}`}
            </li>
        )
    }

    return (
        <div className={styles.container}>
            <input disabled={disabled} autoComplete="off" ref={inputRef} placeholder={placeholderText} {...field}/>
            <ul className={listClassName}>
                {items.map(renderItems)}
            </ul>
        </div>
    )
};
